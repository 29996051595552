const Footer = () => {
  return (
    <footer className="w-full fixed bottom-0 bg-black h-14 flex items-center">
      <div className="container mx-auto">
        <p className="text-xs text-nexaGray">© NEXA 2024</p>
      </div>
    </footer>
  );
};

export default Footer;
