import globe from '@/assets/globe.svg';
import { Footer, Link, Logo, TypeWriter } from '@/components';

function App() {
  return (
    <main className="bg-white">
      <div
        className="fixed w-full h-full bg-no-repeat bg-center bg-cover bg-gradient-to-r from-nexaBlack to-gray-900"
        style={{ backgroundImage: `url(${globe})` }}
      >
        <div className="container mx-auto pt-10">
          <Logo />
        </div>
        <div className="conatiner mx-auto w-full h-4/5 mb-14 flex flex-col gap-14 items-center justify-center">
          <TypeWriter
            texts={['WE ARE WORKING ON OUR AWESOME NEW WEBSITE, STAY TUNED :)']}
          />
          <Link label="Contact Us" />
        </div>
      </div>
      <Footer />
    </main>
  );
}

export default App;
