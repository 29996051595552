import { useState } from 'react';

const Button = ({ label }: { label: string }) => {
  const [hover, setHover] = useState(false);

  return (
    <a
      href="mailto:martin@nexastudio.io"
      target="_blank"
      rel="noreferrer"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="border text-xs text-nexaWhite uppercase border-nexa rounded-full px-6 py-2 flex gap-2 items-center justify-center hover:bg-nexaBlack hover:border-nexaBlue active:bg-nexaBlue"
    >
      <span
        className={`w-2 h-2 bg-nexa duration-500 ${hover && 'rotate-45'} active:bg-nexaBlack`}
      ></span>
      {label}
    </a>
  );
};

export default Button;
