import TypeWriter from 'typewriter-effect';

const TypeWriterComponent = ({ texts }: { texts: string[] }) => {
  return (
    <div className="text-nexaWhite max-w-80 text-center text-sm">
      <TypeWriter
        options={{
          strings: texts,
          autoStart: true,
          loop: true,
          deleteSpeed: 50,
          delay: 80,
        }}
      />
    </div>
  );
};

export default TypeWriterComponent;
